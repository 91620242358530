import { createContext } from '@backyard-ui/utils';
import type { RootProps } from './Root';

export interface UserMenuContext extends Pick<RootProps, 'providers'> {}

const { Provider: UserMenuProvider, useContext: useUserMenuContext } =
  createContext<UserMenuContext>({
    name: '@Header/Actions',
  });

export { UserMenuProvider, useUserMenuContext };
