import { sendGTMEvent } from '../utils/sendGTMEvent';

interface TrackingOptions {
  device?: 'mobile' | 'desktop';
}

export const signup = (
  options: TrackingOptions = {
    device: 'desktop',
  },
) => {
  const { device } = options;

  const EVENT_OBJECT = {
    'event-category': 'interacoes_cadastro',
    'event-action': 'clique_em_cadastrar',
    'event-label':
      device === 'desktop' ? 'modal_minha_conta' : 'menu_mobile_minha_conta',
  };

  sendGTMEvent({ event: 'signup_interactions', value: EVENT_OBJECT });
};

export const login = (
  options: TrackingOptions = {
    device: 'desktop',
  },
) => {
  const { device } = options;

  const EVENT_OBJECT = {
    'event-category': 'interacoes_login',
    'event-action': 'clique_em_login',
    'event-label':
      device === 'desktop' ? 'modal_minha_conta' : 'menu_mobile_minha_conta',
  };

  sendGTMEvent({ event: 'login_interactions', value: EVENT_OBJECT });
};
