import { tv } from 'tailwind-variants';

export default tv({
  base: `
    relative

    py-2

    [&_a]:no-underline
    [&_a]:outline-none
  `,
  slots: {
    link: `hover:underline`,
  },
  variants: {
    theme: {
      default: {
        base: 'bg-green-700 lg:bg-blue-900',
      },
      'black-weekend': {
        base: 'bg-green-700',
      },
    },
  },
});
