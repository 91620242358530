import { Hidden } from '@backyard-ui/core';

import { Authenticated } from './Authenticated';
import { Unauthenticated } from './Unauthenticated';

import { useHeaderStore } from '../../../Header.store';

function UserMenu() {
  const isAuthenticated = useHeaderStore(
    (state) => state.user?.isAuthenticated,
  );

  return (
    <Hidden above="lg">
      {isAuthenticated ? <Authenticated /> : <Unauthenticated />}
    </Hidden>
  );
}

export default UserMenu;
