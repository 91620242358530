import { useHeaderStore } from '../../../Header.store';

import styles from './Logo.styles';

function Logo() {
  const theme = useHeaderStore((state) => state.theme);

  return (
    <a href="/" className={styles()} title="Logo Leroy Merlin">
      <svg viewBox="0 0 149 99" aria-hidden="true">
        <path fill="#72bf44" d="M15.4 89.5h117.4L74.5 31.1 15.4 89.5z" />
        <path
          fill={theme === 'default' ? '#000' : '#fff'}
          d="M19.3 74.5l-5.7 5.6L3.4 69.7 0 73l13.2 13.5 9.1-8.9-3-3.1zm14.4-8.1l-2.6-2.6-5.9 5.8-2.9-2.9 4.8-4.7-2.7-2.7-4.8 4.6-2.4-2.5 5.8-5.6-2.6-2.6-9.4 9.1 13.3 13.3 9.4-9.2zm5.5-5.2L32 53.8l11.6 3.1 3.5-3.4s-8.4-2.9-8.8-2.8c-.1 0 1.8-5.1-2.5-7.4 0 0-3.2-1.7-6.5 1.2l-6.9 6.8 13.3 13.4 3.5-3.5zM28.7 50.4c-.1-.1 2.2-2.1 2.6-2.4.2-.1 1.6-1.1 2.8.1 0 0 2 1.6.1 3.5-2 1.9-2.2 2.1-2.2 2.1l-3.3-3.3zm26.4-4.6c4.7-4.5 3-10.4-.1-13.6-3.8-3.9-10.3-3.4-13.5-.3-3.2 3.1-4.8 9.2-.3 13.8 4.9 5 10.7 3.2 13.9.1zM44.2 34.9c1.2-1.2 3.7-2.3 6.7.8 3.4 3.5 3.5 5.5 1.4 7.5s-4.6 1.5-7.6-1.5c-2.5-2.6-2.3-5.1-.5-6.8zm21.4.5l3.4-3.3-5.2-5.4-3.1-12.9-4 3.9 2.1 7.3-7-2.6-3.7 3.7L60 29.9l5.6 5.5zm6.1-26l-4.5 9.7 2 2 9.7-4.4-7.1 7 3.3 3.3 13.4-13.3-4.3-4.3-9.8 4.5 4.8-9.5-4.4-4.5-13.4 13.3 3.1 3.1 7.2-6.9zm16.1 30.4l2.8-2.7-5.8-5.9 2.9-2.9 4.6 4.7 2.8-2.8-4.6-4.7 2.2-2.2 5.8 5.9 2.7-2.7-9.3-9.4-13.4 13.3 9.3 9.4zm6.5 6.6l7.3-7.3-2.6 12 3.5 3.6s2.8-8.6 2.9-8.9c0 0 3 1.7 6.3-1.2 0 0 3.2-3.1.7-6.7l-8.1-8.2-13.5 13.2 3.5 3.5zM105 35.7l2.5 2.5c.3.3 2 1.6-.1 3.6 0 0-1.7 1.1-3.2-.4l-2.4-2.5c0-.1 2.9-2.9 3.2-3.2zm9.2 30.7l3.2-3.2-5.6-5.7 10.1-10.1-3.4-3.5L105 57.2l9.2 9.2zm19.2-7.4L120 72.3l-3.4-3.4L130 55.6l3.4 3.4zm12.5 12.6l-8.9 8.8 3.9-13.8-4.6-4.6-13.4 13.3 3.2 3.1 8.9-9-3.5 14.5 4.1 4.1L149 74.7l-3.1-3.1z"
        />
      </svg>
    </a>
  );
}

export default Logo;
