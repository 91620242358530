import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { LocationRepository, Location } from './model';

function useGetLocationsByCityName(
  zipcode: string,
  service: LocationRepository['getByCityName'],
  options: UseQueryOptions<Location[], Error, Location[], string[]> = {},
) {
  return useQuery(
    ['getLocationsByCityName', zipcode],
    async () => {
      const locations = await service(zipcode);

      return locations;
    },
    options,
  );
}

export default useGetLocationsByCityName;
