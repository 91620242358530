import { Hidden, IconButton } from '@backyard-ui/core';
import { Menu } from '@backyard-ui/icons';

import { useHeaderStore } from '../../../Header.store';

import styles from './Burger.styles';

function Burger() {
  const { theme, toggleMenu } = useHeaderStore((state) => ({
    theme: state.theme,
    toggleMenu: state.toggleMenu,
  }));

  return (
    <Hidden above="lg">
      <IconButton
        appearance="neutral"
        variant="ghost"
        aria-label="Abrir menu"
        UNSAFE_className={styles({ theme })}
        icon={<Menu />}
        size="md"
        onClick={() => toggleMenu?.()}
      />
    </Hidden>
  );
}

export default Burger;
