import { tv } from 'tailwind-variants';

export default tv({
  base: 'mb-4 px-6 pt-10',
  slots: {
    list: `
      flex
      flex-col
      gap-2
    `,
    item: `
      px-6
      py-2
    `,
    link: `
      block
      w-full

      text-lg
    `,
  },
  variants: {
    theme: {
      default: {
        link: `
          text-info-900
          hover:text-primary-700
          focus:text-primary-700
        `,
      },
      'black-weekend': {
        link: `
          text-white
          hover:text-white/70
          focus:text-white/70
        `,
      },
    },
  },
});
