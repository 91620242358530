import { Button } from '@backyard-ui/core';

import { useHeaderStore } from '../../Header.store';

const EXCHANGE_LIMIT = 1000;

export function JoinProgramButton() {
  const { canJoinLoyalty, isLoyaltyMember, loyaltyPoints } = useHeaderStore(
    (state) => ({
      canJoinLoyalty: state.user?.canJoinLoyalty,
      isLoyaltyMember: state.user?.isLoyaltyMember,
      loyaltyPoints: state.user?.loyaltyPoints,
    }),
  );

  if (!canJoinLoyalty) {
    return null;
  }

  if (!isLoyaltyMember && canJoinLoyalty) {
    return (
      <Button size="xs" variant="outline" asChild isFullWidth>
        <a href="/fidelidade">Faça parte do fidelidade!</a>
      </Button>
    );
  }

  if (isLoyaltyMember) {
    return (
      <Button size="xs" variant="outline" asChild isFullWidth>
        <a href="/fidelidade">
          {Number(loyaltyPoints) >= EXCHANGE_LIMIT
            ? 'Utilize seus pontos!'
            : 'Junte mais pontos!'}
        </a>
      </Button>
    );
  }

  return null;
}
