import React from 'react';
import { isBrowser } from '@backyard-ui/utils';

interface SearchParams {
  [key: string]: string;
}

function getQuery() {
  if (!isBrowser) {
    return {};
  }

  const params = new URLSearchParams(window.location.search);

  return Object.fromEntries(params);
}

function useSearchParams(): SearchParams {
  const [query, setQuery] = React.useState<SearchParams>(getQuery());

  const onQueryChange = () => setQuery(getQuery());

  React.useEffect(() => {
    isBrowser && window.addEventListener('popstate', onQueryChange);

    return () => {
      isBrowser && window.removeEventListener('popstate', onQueryChange);
    };
  }, []);

  return query;
}

export default useSearchParams;
