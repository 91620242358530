import { DropdownMenu } from '@backyard-ui/core';
import { UserProfile } from '../../../UserProfile';

import { useMenuItems } from '../../../../hooks/use-menu-items';

import { redirect } from './UserMenu.utils';

import styles from './UserMenu.styles';

export function Authenticated() {
  const links = useMenuItems();

  return (
    <>
      <DropdownMenu.Item UNSAFE_className={styles()}>
        <UserProfile />
      </DropdownMenu.Item>

      <DropdownMenu.Separator />

      <DropdownMenu.Group>
        {links.map((item) => (
          <DropdownMenu.Item
            key={item.id}
            onSelect={() => redirect.custom(item.url)}
            data-tracking={`popover-item-${item.id}`}
          >
            {item.name}
          </DropdownMenu.Item>
        ))}
        <DropdownMenu.Item onSelect={() => redirect.logout()}>
          <span data-tracking="popover-item-logout">Sair</span>
        </DropdownMenu.Item>
      </DropdownMenu.Group>
    </>
  );
}
