import { tv } from 'tailwind-variants';

export default tv({
  base: `
    fixed
    left-0
    top-0
    z-50

    h-full
    w-full
    -translate-x-full
    flex-col

    overflow-auto

    transition-transform
    duration-150
    ease-linear

    motion-reduce:-left-full
    motion-reduce:transform-none

    md:w-[calc(100vw-theme('spacing.14'))]

    lg:relative
    lg:z-0
    lg:h-auto
    lg:w-auto
    lg:transform-none
    lg:overflow-visible
    lg:bg-transparent
    lg:pb-6
    lg:motion-reduce:left-0
  `,
  slots: {
    list: `
      flex

      flex-col
      gap-2

      lg:flex-row
      lg:gap-6
    `,
    item: 'relative',
    trigger: `
      line-clamp-1

      flex
      h-full
      w-full
      items-center
      justify-between
      gap-2

      px-6
      py-2

      text-lg

      outline-0

      transition-colors
      duration-150
      ease-linear

      lg:px-0
      lg:py-0
      lg:text-md
      lg:font-bold
      lg:last:[&[data-state=open]>svg]:-rotate-180

      [&_span]:flex
      [&_span]:h-full
      [&_span]:items-center

      [&_svg]:transition-transform
      [&_svg]:duration-100
      [&_svg]:ease-linear

      first:[&_svg]:hidden
      first:[&_svg]:h-6
      first:[&_svg]:w-6

      last:[&_svg]:h-2
      last:[&_svg]:w-2
      last:[&_svg]:-rotate-90

      lg:first:[&_svg]:block
      lg:last:[&_svg]:rotate-0
    `,
    sub: `
      line-clamp-1

      flex
      w-full
      items-center

      gap-2
      px-6

      py-2.5

      text-left
      text-lg
      outline-0

      transition-colors

      duration-150
      ease-linear
      lg:px-3

      lg:text-md
      lg:font-bold

      [&_span]:w-full
      [&_span]:truncate

      first:[&_svg]:h-5
      first:[&_svg]:w-5

      last:[&_svg]:h-2
      last:[&_svg]:w-2

    `,
    subList: 'mb-4 lg:mb-0',
    content: `
      fixed
      left-0
      top-0

      z-10

      h-full
      min-h-[calc(100%+2px)]

      w-full
      overflow-y-auto

      data-[state=closed]:animate-exit-to-left
      data-[state=open]:animate-enter-from-left

      lg:absolute
      lg:top-[calc(100%+theme('spacing.6'))]
      lg:z-50

      lg:h-auto
      lg:w-56

      lg:overflow-y-visible
      lg:border
      lg:shadow-xs
      lg:data-[state]:animate-none
    `,
    closeButton: `
      absolute
      right-2
      top-2
    `,
  },
  variants: {
    isOpen: {
      true: 'transform-none motion-reduce:left-0',
    },
    hasChildren: {
      true: {
        sub: `
          cursor-pointer
          lg:font-normal
        `,
      },
    },
    hasSub: {
      true: {
        content: `
          lg:-top-[1px]
          lg:left-[calc(100%+1px)]
        `,
      },
    },
    isAssistedSale: {
      false: {
        item: 'lg:last-of-type:ml-auto',
      },
    },
    theme: {
      default: {
        base: 'bg-white',
        trigger: `
          text-info-900
          hover:text-primary-700
          focus:text-primary-700
        `,
        content: 'bg-white lg:border-gray-100',
        sub: `
          text-info-900
          hover:text-primary-700
          focus:text-primary-700
          data-[state=open]:text-primary-700
        `,
      },
      'black-weekend': {
        base: 'bg-black',
        trigger: `
          text-white
          hover:text-green-500
          focus:text-green-500
        `,
        content: 'bg-black lg:border-black',
        sub: `
          text-white
          hover:text-white/70
          focus:text-white/70
          data-[state=open]:text-green-500
        `,
      },
    },
  },
  compoundVariants: [
    {
      hasChildren: true,
      theme: 'default',
      className: {
        sub: 'lg:hover:bg-primary-100 lg:hover:font-bold',
      },
    },
    {
      hasChildren: true,
      theme: 'black-weekend',
      className: {
        sub: 'hover:text-black lg:hover:bg-white lg:hover:font-bold',
      },
    },
  ],
});
