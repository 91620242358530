import { DropdownMenu, Button, Text } from '@backyard-ui/core';

import * as Icons from './Icons';

import { redirect } from './UserMenu.utils';
import * as tracker from '../../../../analytics/UserMenu.tracker';

import { useUserMenuContext } from '../Actions.context';

const PROVIDERS: Array<{
  name: 'apple' | 'facebook' | 'google';
  text: string;
  url: string;
}> = [
  {
    name: 'apple',
    text: 'Iniciar sessão com a Apple',
    url: '/apple',
  },
  {
    name: 'facebook',
    text: 'Iniciar sessão com o Facebook',
    url: '/facebook',
  },
  {
    name: 'google',
    text: 'Iniciar sessão com o Google',
    url: '/google',
  },
];

export function Unauthenticated() {
  const { providers } = useUserMenuContext();

  const handleRedirectProvider = (url: string) => {
    redirect.social(`login${url}`);
    tracker.login();
  };

  const handleRedirectLogin = () => {
    redirect.login();
    tracker.login();
  };

  return (
    <>
      {PROVIDERS.map((provider) => {
        if (providers?.disable?.includes(provider.name)) {
          return null;
        }

        return (
          <DropdownMenu.Item
            key={provider.name}
            onSelect={() => handleRedirectProvider(provider.url)}
          >
            <Button
              iconLeft={Icons[provider.name]}
              social={provider.name}
              variant={provider.name === 'google' ? 'outline' : undefined}
              isFullWidth
            >
              {provider.text}
            </Button>
          </DropdownMenu.Item>
        );
      })}

      <DropdownMenu.Separator />
      <DropdownMenu.Item onSelect={handleRedirectLogin}>
        <Button isFullWidth>Entrar</Button>
      </DropdownMenu.Item>
      <DropdownMenu.Item onSelect={() => tracker.signup()}>
        <Text size="sm" align="center" UNSAFE_className="w-full" asChild>
          <span>
            Cliente novo?{' '}
            <a href="/cadastre-se" className="text-green-600 hover:underline">
              Cadastre-se
            </a>
          </span>
        </Text>
      </DropdownMenu.Item>
    </>
  );
}
