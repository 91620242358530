import { HTMLAttributes } from 'react';
import { Visible, Icon } from '@backyard-ui/core';

import { useHeaderStore } from '../../../Header.store';

import styles from './Actions.styles';

interface WishlistProps extends HTMLAttributes<HTMLAnchorElement> {}

function Wishlist(props: WishlistProps) {
  const { isAssistedSale, theme } = useHeaderStore((state) => ({
    isAssistedSale: state.user?.isAssistedSale,
    theme: state.theme,
  }));

  if (isAssistedSale) {
    return null;
  }

  return (
    <Visible above="lg">
      <a
        href="/minhas-listas-de-desejos"
        aria-label="Lista de favoritos"
        className={styles().item({ theme })}
        {...props}
      >
        <Icon
          color="current"
          size={{
            initial: 'lg',
            lg: '2xl',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 33 31"
          >
            <path
              fill="currentColor"
              d="M16.941 30.494c-.41 0-.81-.09-1.15-.29-5.169-2.889-8.868-6.018-11.627-9.838-2.28-3.14-3.33-6.309-3.19-9.668.22-5.349 4.37-9.558 9.658-9.788 2.31-.1 4.47.62 6.34 2.1.38-.29.769-.57 1.219-.82C20.91.65 24.08.48 26.889 1.73c2.91 1.29 5.08 3.98 5.79 7.178.66 2.96.17 5.94-1.49 9.129-1.65 3.17-4.17 5.999-7.69 8.638-1.299.97-2.669 1.84-3.988 2.67-.4.25-.8.5-1.2.76-.4.26-.89.39-1.37.39ZM11.072 4.46h-.28c-3.429.15-6.119 2.89-6.259 6.38-.1 2.529.72 4.968 2.51 7.448 2.34 3.24 5.499 5.949 9.888 8.478.23-.14.45-.29.68-.43 1.32-.83 2.57-1.62 3.76-2.51 3.089-2.319 5.268-4.748 6.668-7.438 1.26-2.41 1.64-4.61 1.17-6.709-.47-2.11-1.88-3.869-3.76-4.709-1.79-.8-3.749-.69-5.498.3-.4.23-.82.55-1.25.9-.24.19-.48.38-.74.57l-1.18.87-1.16-1.07-.31-.28c-1.27-1.21-2.659-1.81-4.229-1.81l-.01.01Z"
            />
          </svg>
        </Icon>
      </a>
    </Visible>
  );
}

export default Wishlist;
