import { Skeleton, DropdownMenu, Stack } from '@backyard-ui/core';
import styles from './UserMenu.styles';

export function Loading() {
  return (
    <div data-testid="UserMenu-loading">
      <DropdownMenu.Item UNSAFE_className={styles()}>
        <Stack
          direction="column"
          justify="flex-start"
          spacing={4}
          className="w-full"
        >
          <Stack align="center" spacing={4}>
            <Skeleton radius="full" width="40px" height="40px" />

            <Skeleton width="160px" height="24px" />
          </Stack>
        </Stack>

        <Skeleton width="216px" height="40px" />
      </DropdownMenu.Item>

      <DropdownMenu.Separator />

      <DropdownMenu.Group>
        <Stack direction="column" className="w-full" spacing={'0.5'}>
          <Skeleton width="100%" height="35px" />
          <Skeleton width="100%" height="35px" />
          <Skeleton width="100%" height="35px" />
          <Skeleton width="100%" height="35px" />
          <Skeleton width="100%" height="35px" />
        </Stack>
      </DropdownMenu.Group>
    </div>
  );
}
