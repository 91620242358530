import { HTMLAttributes } from 'react';
import { Icon } from '@backyard-ui/core';

import { useHeaderStore } from '../../../Header.store';

import styles from './Actions.styles';

interface CartProps extends HTMLAttributes<HTMLButtonElement> {}

function Cart(props: CartProps) {
  const { cartItems, theme } = useHeaderStore((state) => ({
    cartItems: state.cartItems,
    theme: state.theme,
  }));

  return (
    <button
      aria-label="Meu carrinho"
      data-items={cartItems}
      className={styles({
        theme,
        hasItems: Boolean(cartItems),
      }).item()}
      {...props}
    >
      <Icon
        color="current"
        size={{
          initial: 'lg',
          lg: '2xl',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 31">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M31.578 5.297a1.385 1.385 0 0 1 1.345 1.704L30.53 17.113a1.38 1.38 0 0 1-1.346 1.066h-.002l-18.582-.01-1.622 3.543h18.66c.765 0 1.384.62 1.384 1.387 0 .29-.11.544-.263.768.348.611.563 1.309.563 2.06 0 2.316-1.885 4.2-4.2 4.2a4.205 4.205 0 0 1-4.2-4.2c0-.508.105-.991.27-1.444h-10.38c.166.453.271.936.271 1.445 0 2.315-1.885 4.2-4.2 4.2a4.205 4.205 0 0 1-4.2-4.2c0-1.96 1.357-3.6 3.176-4.06l2.442-5.334L7.08 3.067H2.346a1.385 1.385 0 1 1 0-2.77h5.999c.715 0 1.313.546 1.379 1.26l.34 3.74h21.513ZM10.979 15.398l17.11.011 1.735-7.34H10.315l.65 7.176.014.153Zm12.712 10.53c0 .787.642 1.43 1.43 1.43a1.43 1.43 0 1 0-1.43-1.43Zm-16.808 1.43a1.43 1.43 0 1 1 1.429-1.43c0 .787-.64 1.43-1.429 1.43Z"
            clipRule="evenodd"
          />
        </svg>
      </Icon>
    </button>
  );
}

export default Cart;
