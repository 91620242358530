import { create } from 'zustand';

import { NO_CHILDREN_NAV_ID } from './Navigation.data';

interface NavigationState {
  /**
   * Current selected category
   */
  currentCategory: string;

  /**
   * Update category
   */
  setCurrentCategory: (category: string) => void;
}

export const useNavigationStore = create<NavigationState>((set) => ({
  currentCategory: NO_CHILDREN_NAV_ID,
  setCurrentCategory: (category) =>
    set(() => ({
      currentCategory: category,
    })),
}));
