import cookies from 'js-cookie';
import { isBrowser } from '@backyard-ui/utils';

import * as storage from '../../../../utils/local-storage';

function social(target: string) {
  if (isBrowser) {
    window.location.href = `/${target}?redirect=${encodeURIComponent(
      window.location.href,
    )}`;
  }

  return '';
}

function login() {
  if (isBrowser) {
    window.location.href = '/login';
  }

  return '';
}

function logout() {
  if (isBrowser) {
    window.location.href = `/logout?redirect=${encodeURIComponent(
      window.location.href,
    )}`;

    if (storage.has('employee-notification-dispatched')) {
      storage.remove('employee-notification-dispatched');
    }

    cookies.remove('user');
    storage.removeAll('@celebre');
  }

  return '';
}

function custom(target: string) {
  if (isBrowser) {
    window.location.href = target;
  }

  return '';
}

export const redirect = {
  social,
  login,
  logout,
  custom,
};
