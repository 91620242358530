interface DataLayer {
  dataLayer?: Object[];
  [key: string]: any;
}
declare global {
  interface Window extends DataLayer {}
  interface globalThis extends DataLayer {}
}

interface SendGTMEventOptions {
  event?: string;
  value: string | Object;
}

const dataLayerName = 'dataLayer';

export function sendGTMEvent(options: SendGTMEventOptions) {
  const { event = 'search', value = {} } = options;

  if (window[dataLayerName]) {
    window[dataLayerName].push({ event, ...value });
  }
}
