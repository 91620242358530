import { tv } from 'tailwind-variants';

export default tv({
  base: `
    mb-4

    flex
    w-full

    items-center
    justify-between

    border-b

    px-6
    py-4
  `,

  slots: {
    trigger: `
      flex
      items-center

      text-xl
      font-medium

      outline-none

      [&_svg]:mr-2
      [&_svg]:h-3
      [&_svg]:w-3
    `,
  },
  variants: {
    theme: {
      default: {
        base: 'border-gray-300',
        trigger: `
          text-blue-900

          hover:text-green-700
          focus:text-green-700
        `,
      },
      'black-weekend': {
        base: 'border-neutral-800',
        trigger: `
          text-white

          hover:text-white/70
          focus:text-white/70
        `,
      },
    },
  },
});
