import React from 'react';
import {
  Container,
  Flex,
  Stack,
  Text,
  Visible,
  Popover,
  Button,
  Hidden,
} from '@backyard-ui/core';

import Dialog from './Dialog';

import { useLocationStore } from './TopBar.store';
import { useHeaderStore } from '../../Header.store';
import { useLocationPopover } from './useLocationPopover';

import type { Coordinates, LocationRepository, ZipCode } from './model';

import styles from './TopBar.styles';

export interface TopBarProps {
  handleLocationRecontext: (value: Coordinates | ZipCode) => Promise<void>;
  locationRepository: LocationRepository;
}

function TopBar(props: TopBarProps) {
  const { handleLocationRecontext, locationRepository } = props;

  const { ui } = useLocationStore();
  const { regionName, theme } = useHeaderStore((state) => ({
    theme: state.theme,
    regionName: state.locationContext.regionName,
  }));

  const {
    isPopoverOpen,
    onPopoverOpenChange,
    onLocationPopperChangeClick,
    onLocationPopperConfirmClick,
  } = useLocationPopover({
    toggleModal: ui.toggleModal,
  });

  return (
    <div className={styles({ theme }).base()}>
      <Dialog
        handleLocationRecontext={handleLocationRecontext}
        locationRepository={locationRepository}
      />

      <Container size="6xl">
        <Flex
          justify={{
            initial: 'center',
            lg: 'space-between',
          }}
        >
          <Stack justify="center" align="center" spacing="3">
            {!regionName ? (
              <React.Fragment>
                <Text
                  color="white"
                  size="sm"
                  asChild
                  UNSAFE_className={styles().link()}
                >
                  <button onClick={ui.toggleModal}>Onde você está?</button>
                </Text>
              </React.Fragment>
            ) : (
              <Popover.Root
                isOpen={isPopoverOpen}
                onOpenChange={onPopoverOpenChange}
              >
                <Popover.Trigger>
                  <Stack align="center" spacing={1}>
                    <Hidden above="lg">
                      <Text
                        color="white"
                        size="sm"
                        asChild
                        UNSAFE_className={styles().link()}
                      >
                        <span>Onde você está?</span>
                      </Text>
                    </Hidden>
                    <Text
                      color="white"
                      size="sm"
                      asChild
                      UNSAFE_className={styles().link()}
                      data-testid="location-region-name"
                    >
                      <span>{regionName}</span>
                    </Text>
                  </Stack>
                </Popover.Trigger>

                <Popover.Content
                  onPointerDownOutside={(event) => event.preventDefault()}
                  onInteractOutside={(event) => event.preventDefault()}
                  data-testid="location-popover"
                >
                  <Popover.Arrow />
                  <Popover.Body>
                    <Stack direction="column">
                      <Text color="neutral-900" size="sm">
                        Para acessar produtos e ofertas da sua região,
                        identificamos que você está em{' '}
                        <Text color="primary-600" size="sm" asChild>
                          <span>{regionName}</span>
                        </Text>
                      </Text>
                      <Stack>
                        <Popover.Close asChild>
                          <Button
                            appearance="primary"
                            variant="outline"
                            size="xs"
                            isFullWidth
                            onClick={onLocationPopperChangeClick}
                          >
                            Trocar
                          </Button>
                        </Popover.Close>
                        <Popover.Close asChild>
                          <Button
                            appearance="primary"
                            size="xs"
                            isFullWidth
                            onClick={onLocationPopperConfirmClick}
                          >
                            Continuar
                          </Button>
                        </Popover.Close>
                      </Stack>
                    </Stack>
                  </Popover.Body>
                </Popover.Content>
              </Popover.Root>
            )}

            <Visible above="lg">
              <Text color="white" size="sm" asChild>
                <span>|</span>
              </Text>
              <Text
                color="white"
                size="sm"
                UNSAFE_className={styles().link()}
                asChild
              >
                <a href="/lojas">Lojas</a>
              </Text>
            </Visible>
          </Stack>

          <Visible above="lg">
            <Text color="white" size="sm" weight="bold" asChild>
              <span>
                Compre pelo telefone (somente vendas): 4007-1380 (Capitais) -
                0800-602-1380 (Demais regiões).
              </span>
            </Text>

            <Text
              color="white"
              size="sm"
              UNSAFE_className={styles().link()}
              asChild
            >
              <a href="/modular/dicasdeseguranca">Dicas de segurança</a>
            </Text>

            <Text
              color="white"
              size="sm"
              UNSAFE_className={styles().link()}
              asChild
            >
              <a href="/fale-conosco">Precisa de ajuda?</a>
            </Text>
          </Visible>
        </Flex>
      </Container>
    </div>
  );
}

export default React.memo(TopBar);
