import { useHeaderStore } from '../../Header.store';

export const STORES_LINK = {
  id: 'stores',
  url: '/lojas',
  name: 'Lojas',
};

export const SAFETY_LINK = {
  id: 'safety',
  url: '/modular/dicasdeseguranca',
  name: 'Dicas de segurança',
};

export const ACCOUNT_LINK = {
  id: 'account',
  name: 'Meu perfil',
  url: '/minha-conta',
};

export const ORDERS_LINK = {
  id: 'orders',
  name: 'Pedidos',
  url: '/meus-pedidos',
};

export const LOYALTY_LINK = {
  id: 'loyalty',
  name: 'Fidelidade',
  url: '/fidelidade',
};

export const WISHLIST_LINK = {
  id: 'favorites',
  name: 'Favoritos',
  url: '/minhas-listas-de-desejos',
};

function useMenuItems() {
  const { canJoinLoyalty, isAssistedSale } = useHeaderStore((state) => ({
    canJoinLoyalty: state.user?.canJoinLoyalty,
    isAssistedSale: state.user?.isAssistedSale,
  }));

  if (canJoinLoyalty) {
    return [ACCOUNT_LINK, ORDERS_LINK, LOYALTY_LINK, WISHLIST_LINK];
  }

  if (isAssistedSale) {
    return [ACCOUNT_LINK, ORDERS_LINK];
  }

  return [ACCOUNT_LINK, ORDERS_LINK, WISHLIST_LINK];
}

export default useMenuItems;
