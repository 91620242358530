import { tv } from 'tailwind-variants';

export default tv({
  base: `
    pointer-events-auto

    fixed
    inset-0

    z-50

    bg-black-alpha-500
    transition-opacity

    data-[state=open]:animate-in
    data-[state=closed]:animate-out
    data-[state=closed]:fade-out-0
    data-[state=open]:fade-in-0
  `,
});
