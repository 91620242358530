import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { NavigationRepository, SubCategoryChildren } from './model';

function useGetChildrenById(
  id: string,
  service: NavigationRepository['getChildrenById'],
  options: UseQueryOptions<
    SubCategoryChildren,
    Error,
    SubCategoryChildren,
    string[]
  > = {},
) {
  return useQuery(
    ['getChildrenById', id],
    async () => {
      const children = await service(id);

      return children;
    },
    options,
  );
}

export default useGetChildrenById;
