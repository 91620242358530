import { tv } from 'tailwind-variants';

export default tv({
  base: '',

  slots: {
    list: [
      '!absolute',
      'z-50',

      'w-full',
      'flex-col',

      'mt-2',

      'overflow-hidden',
      'pointer-events-auto',

      'rounded-md',
      'bg-white',
      'text-neutral-900',

      'shadow-sm',

      'will-change-transform',
      'animate-in',
      'duration-100',

      'data-[state=open]:slide-in-from-top-1',
      'data-[state]:motion-reduce:animate-none',

      'data-[state=open]:flex',
      'data-[state=closed]:hidden',
    ],
    item: [
      'cursor-pointer',
      'px-2',
      'py-2',

      'text-gray-600',

      'first-of-type:rounded-tl-md',
      'first-of-type:rounded-tr-md',
      'last-of-type:rounded-bl-md',
      'last-of-type:rounded-br-md',

      'data-[state=active]:bg-primary-600',
      'data-[state=active]:text-white',
    ],
    scroll: 'max-h-[theme(spacing.48)]',
  },
});
