import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { LocationRepository, Location } from './model';

function useGetLocationByZipcode(
  zipcode: string,
  service: LocationRepository['getByZipcode'],
  options: UseQueryOptions<Location, Error, Location, string[]> = {},
) {
  return useQuery(
    ['getLocationByZipcode', zipcode],
    async () => {
      const location = await service(zipcode);

      return location;
    },
    options,
  );
}

export default useGetLocationByZipcode;
