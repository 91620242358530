import { Stack } from '@backyard-ui/core';

import Wishlist from './Wishlist';
import UserMenu from './UserMenu/UserMenu';
import Cart from './Cart';

import { UserMenuProvider } from './Actions.context';

export interface RootProps {
  /**
   *
   */
  onClickWishlist?: () => void;

  /**
   *
   */
  onClickUserMenu?: () => void;

  /**
   *
   */
  onClickCart?: () => void;

  /**
   * Social provider login
   */
  providers?: {
    disable?: Array<'apple' | 'facebook' | 'google'>;
  };
}

function Root(props: RootProps) {
  const { onClickWishlist, onClickUserMenu, onClickCart, providers } = props;

  return (
    <UserMenuProvider value={{ providers }}>
      <Stack spacing="8">
        <Wishlist onClick={onClickWishlist} />
        <UserMenu onClick={onClickUserMenu} {...providers} />
        <Cart onClick={onClickCart} />
      </Stack>
    </UserMenuProvider>
  );
}

export default Root;
