import { Stack, Avatar, Icon, Text } from '@backyard-ui/core';
import { BuildingOutline, UserOutline } from '@backyard-ui/icons';

import { JoinProgramButton } from './JoinProgramButton';

import { useHeaderStore } from '../../Header.store';

import styles from './UserProfile.styles';

function UserProfile() {
  const { user, theme } = useHeaderStore((state) => ({
    user: state.user,
    theme: state.theme,
  }));

  return (
    <Stack
      direction="column"
      justify="flex-start"
      spacing={4}
      className="w-full"
    >
      <Stack align="center" spacing={4}>
        <div className={styles({ loyalty: user?.isLoyaltyMember })}>
          <Avatar.Root size="sm" appearance="info">
            <Avatar.Image
              src={user?.picture}
              alt={`Imagem de perfil de ${user?.name}`}
            />

            <Avatar.Fallback>
              <Icon size="sm">
                {user?.isLegalUser ? (
                  <BuildingOutline data-testid="container-user-menu-building" />
                ) : (
                  <UserOutline data-testid="container-user-menu-avatar" />
                )}
              </Icon>
            </Avatar.Fallback>
          </Avatar.Root>
        </div>

        <Stack direction="column" spacing={0}>
          <Text
            color={theme === 'default' ? 'info-900' : 'white'}
            size="lg"
            transform="capitalize"
            weight="bold"
          >
            {user?.name}
          </Text>

          {user?.isLoyaltyMember && (
            <Text
              color={theme === 'default' ? 'neutral-700' : 'white'}
              size="md"
            >
              {`${user?.loyaltyPoints || 0} ${
                user?.loyaltyPoints === 1 ? 'ponto' : 'pontos'
              }`}
            </Text>
          )}
        </Stack>
      </Stack>

      <JoinProgramButton />
    </Stack>
  );
}

export default UserProfile;
