import { tv } from 'tailwind-variants';

export default tv({
  base: '',
  variants: {
    loyalty: {
      true: `
        flex
        rounded-full
        border-2
        border-purple-300
        bg-white

        p-0.5
      `,
    },
  },
});
