import React from 'react';
import {
  Root,
  List,
  Item,
  Trigger,
  Link,
} from '@radix-ui/react-navigation-menu';

import { CloseButton, Container } from '@backyard-ui/core';
import { Menu, ChevronDown } from '@backyard-ui/icons';
import { useMediaQuery } from '@backyard-ui/hooks';

import { Overlay } from './Overlay';
import { Separator } from './Separator';
import { UserMenu } from './UserMenu';

import { Categories } from './Categories';

import {
  STORES_LINK,
  SAFETY_LINK,
} from '../../hooks/use-menu-items/use-menu-items';
import { NO_CHILDREN_NAV_ID } from './Navigation.data';
import { useHeaderStore } from '../../Header.store';

import * as tracker from './Navigation.tracker';

import type { CategoriesTree, NavigationRepository } from './model';
import styles from './Navigation.styles';

export interface NavigationProps {
  data: CategoriesTree;
  navigationRepository: NavigationRepository;
}

function Navigation(props: NavigationProps) {
  const { data, navigationRepository } = props;

  const { theme, isMenuOpen, isAssistedSale, isAuthenticated, toggleMenu } =
    useHeaderStore((state) => ({
      theme: state.theme,
      isMenuOpen: state.ui.isMenuOpen,
      isAssistedSale: state.user?.isAssistedSale,
      isAuthenticated: state.user?.isAuthenticated,
      toggleMenu: state.toggleMenu,
    }));
  const isDesktop = useMediaQuery('lg');

  return (
    <React.Fragment>
      <Overlay>
        <Container
          size="6xl"
          className={styles({
            theme,
            isOpen: isMenuOpen,
          }).base()}
        >
          <Root orientation={isDesktop ? 'horizontal' : 'vertical'}>
            <UserMenu />

            {!isDesktop && <Separator />}

            <List className={styles().list()}>
              {data.map(({ id, name, url, items }) => {
                const isDepartment = name === 'Departamentos';
                const hasDepartmentElements = isDepartment && !isAssistedSale;

                return items?.length ? (
                  <Item
                    key={`${name}-${id}`}
                    className={styles().item({
                      isAssistedSale: Boolean(isAssistedSale),
                    })}
                  >
                    <Trigger
                      aria-label={name}
                      className={styles().trigger({ theme })}
                    >
                      {hasDepartmentElements && <Menu />}
                      <span>{name}</span>
                      {!hasDepartmentElements && <ChevronDown />}
                      {hasDepartmentElements && !isDesktop && <ChevronDown />}
                    </Trigger>

                    <Categories
                      data={items}
                      name={name}
                      navigationRepository={navigationRepository}
                    />
                  </Item>
                ) : (
                  <Item
                    key={`${name}-${id}`}
                    value={NO_CHILDREN_NAV_ID}
                    className={styles().item({
                      isAssistedSale: Boolean(isAssistedSale),
                    })}
                  >
                    <Link
                      href={url}
                      aria-label={name}
                      data-label={name}
                      className={styles().trigger({ theme })}
                      onClick={() =>
                        tracker.navigation({
                          device: isDesktop ? 'desktop' : 'mobile',
                          id: id || 'NO_ID',
                          originMenuName: name,
                          tree: name,
                        })
                      }
                    >
                      <span>{name}</span>
                    </Link>
                  </Item>
                );
              })}

              {!isDesktop && (
                <React.Fragment>
                  <Item
                    key={`${STORES_LINK.name}-${STORES_LINK.id}`}
                    className={styles().item()}
                  >
                    <Link
                      href={STORES_LINK.url}
                      aria-label={STORES_LINK.name}
                      className={styles().trigger({ theme })}
                    >
                      <span>{STORES_LINK.name}</span>
                    </Link>
                  </Item>

                  <Item
                    key={`${SAFETY_LINK.name}-${SAFETY_LINK.id}`}
                    className={styles().item()}
                  >
                    <Link
                      href={SAFETY_LINK.url}
                      aria-label={SAFETY_LINK.name}
                      className={styles().trigger({ theme })}
                    >
                      <span>{SAFETY_LINK.name}</span>
                    </Link>
                  </Item>
                </React.Fragment>
              )}
            </List>

            {!isDesktop && isAuthenticated && (
              <List className={styles().subList()}>
                <Separator />

                <Item className={styles().item()}>
                  <Trigger
                    className={styles().trigger({ theme })}
                    data-tracking="mobile-menu-logout"
                  >
                    Sair
                  </Trigger>
                </Item>
              </List>
            )}

            {!isDesktop && (
              <CloseButton
                size="xs"
                ariaLabel="Fechar menu de navegação"
                onClick={toggleMenu}
                UNSAFE_className={styles().closeButton()}
              />
            )}
          </Root>
        </Container>
      </Overlay>
    </React.Fragment>
  );
}

export default Navigation;
