import { tv } from 'tailwind-variants';

export default tv({
  base: `
    relative
    z-10

    order-1
    mt-4
    w-full

    duration-100
    ease-in
    will-change-transform

    md:mt-6
    md:translate-y-0

    lg:order-[0]
    lg:ml-8
    lg:mr-16
    lg:mt-0
    lg:flex-1
  `,
  slots: {
    form: 'w-full',
    input: `
      text-base

      h-10

      border
      border-transparent

      bg-gray-100

      font-bold

      reset-search
      placeholder:text-gray-600

      hover:border-gray-300

      focus:border-gray-400
      focus:bg-white
      focus:ring-gray-200

      lg:h-12
      lg:text-lg
    `,
    panel: `
      pointer-events-auto

      absolute
      z-50

      -ml-2
      -mr-2

      flex-col

      overflow-hidden
      bg-white

      p-6

      text-neutral-900
      shadow-sm

      duration-100
      will-change-transform
      animate-in

      data-[state=open]:flex
      data-[state=closed]:hidden

      data-[state=open]:slide-in-from-top-2
      data-[state]:motion-reduce:animate-none

      md:ml-0
      md:mt-2
      md:w-full
      md:rounded-md
    `,
    grid: `
      grid
      grid-cols-1
      gap-y-4
      md:grid-cols-2
      md:gap-x-4

      [&>[data-area="products"]]:[grid-area:products]
      [&>[data-area="recents"]]:[grid-area:recents]
      [&>[data-area="suggestions"]]:[grid-area:suggestions]
    `,
    gridRecents: `
      border-b
      border-gray-100

      pb-4
    `,
    gridProducts: `
      border-l
      border-gray-100

      pl-4
      `,
    list: `
      mt-4
      grid
      gap-4
      break-all
    `,
    suggestion: `
      cursor-pointer

      [&[aria-selected='true']_a]:text-green-600
      [&[aria-selected='true']_span]:text-green-600

      [&_a]:transition-colors
      [&_a]:duration-100
      [&_a]:ease-in
      [&_a]:hover:text-green-600
      [&_span]:hover:text-green-600
    `,
    product: `
      group

      [&[aria-selected='true']_span:first-of-type]:text-green-600
      [&_span]:transition-colors
      [&_span]:duration-100
      [&_span]:ease-in
    `,
    picture: `
      h-10
      w-10

      shrink-0
      overflow-hidden

      rounded-md
      border
      border-gray-300

      [&_img]:h-full
      [&_img]:w-full
      [&_img]:bg-gray-100
      [&_img]:transition-transform
      [&_img]:duration-100
      [&_img]:ease-in

      group-hover:[&_img]:scale-110
    `,
  },
  variants: {
    isOpen: {
      true: {
        base: `
          mt-0
          -translate-y-10
        `,
        panel: `
          mt-[calc(theme(spacing.5)+1px)]
          w-[calc(100%+theme(spacing.4))]
        `,
      },
    },
    areas: {
      true: {
        grid: '[grid-template-areas:"recents_products"_"suggestions_products"_"._products"]',
      },
      false: {
        grid: '[grid-template-areas:"suggestions_products"]',
      },
    },
  },
});
