import { tv } from 'tailwind-variants';

export default tv({
  base: `
    h-auto
    p-0

    [&_svg]:h-6
    [&_svg]:w-6
  `,
  variants: {
    theme: {
      default: 'text-blue-900',
      'black-weekend': 'text-white hover:bg-neutral-700 active:bg-neutral-800',
    },
  },
});
