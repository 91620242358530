import { useHeaderStore } from '../../../Header.store';

import styles from './Separator.styles';

function Separator() {
  const theme = useHeaderStore((state) => state.theme);

  return <hr className={styles({ theme })} />;
}

export default Separator;
