import React from 'react';
import { List, Item, Link } from '@radix-ui/react-navigation-menu';

import { UserProfile } from '../../UserProfile';

import { useHeaderStore } from '../../../Header.store';
import { useMenuItems } from '../../../hooks/use-menu-items';

import styles from './UserMenu.styles';

export function Authenticated() {
  const theme = useHeaderStore((state) => state.theme);
  const links = useMenuItems();

  return (
    <React.Fragment>
      <div className={styles().base()}>
        <UserProfile />
      </div>

      <List className={styles().list()}>
        {links.map((item) => (
          <Item key={item.id} className={styles().item()}>
            <Link
              href={item.url}
              aria-label={item.name}
              className={styles().link({ theme })}
              data-tracking={`mobile-menu-item-${item.id}`}
            >
              {item.name}
            </Link>
          </Item>
        ))}
      </List>
    </React.Fragment>
  );
}
