import type { ReactNode } from 'react';
import { Container, Flex } from '@backyard-ui/core';

import styles from './Container.styles';

interface ContentProps {
  children: ReactNode;
}

function Content(props: ContentProps) {
  const { children } = props;

  return (
    <Container size="6xl" className={styles()}>
      <Flex align="center" justify="space-between" wrap="wrap">
        {children}
      </Flex>
    </Container>
  );
}

export default Content;
