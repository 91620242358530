import type { FC } from 'react';
import {
  BathtubOutline,
  BeddingOutline,
  AirConditionerOutline,
  KitchenOutline,
  FrameOutline,
  FridgeOutline,
  DoorHingeOutline,
  DrillOutline,
  PendantOutline,
  FenceOutline,
  BroomOutline,
  ShovelOutline,
  PlugOutline,
  FaucetOutline,
  WoodOutline,
  CouchOutline,
  HangerOutline,
  PawOutline,
  PaintBucketOutline,
  FloorOutline,
  DoorOutline,
  CameraSecurityOutline,
  MixerOutline,
} from '@backyard-ui/icons';
// import { AudioIcon, ComputerIcon, HouseHoldItems } from '../Icons';

export const ICONS_DICTIONARY: {
  [key: string]: FC;
} = {
  // '5cd1ca45f846aa1f58061617': AudioIcon, // Áudio, Vídeo e TVs
  '94301c9bf86859d0b3ea8a69': BathtubOutline, // Banheiros
  '5c87fc0df846aa23bb05e4f7': BeddingOutline, // Cama, Mesa e Banho
  f48f3f336dd76e7234e119ce: AirConditionerOutline, // Climatização e Ventilação
  e315adee7e1ed4ea6e920819: KitchenOutline, // Cozinhas e Áreas de Serviço
  ba87d76c99d8a1523471c0b6: FrameOutline, // Decoração
  '5ba3b5e4c7b7920fd4665686': FridgeOutline, // Eletrodomésticos
  '5b800040ca7b80459f21562f': MixerOutline, // Eletroportáteis
  '4b114b65dfc74e7228e6750f': DoorHingeOutline, // Ferragens
  b42110d83574a6a9fbdcbcfb: DrillOutline, // Ferramentas
  // ba33c74862bb71863af38c66: ComputerIcon, // Informática
  '9817332c9cde3a4dfc02372e': PendantOutline, // Iluminação
  '59668bf188e776c91cf82a21': FenceOutline, // Jardim e Varanda
  d82c080656ab1649302b06d9: BroomOutline, // Limpeza da Casa
  '1a8ad120950bca99adda415c': WoodOutline, // Madeiras
  a2e3587143e67e3561d6b90d: ShovelOutline, // Materiais de Construção
  cd9681d503b6b5b69ce0990e: PlugOutline, // Materiais Elétricos
  fd8728d8714555dec83a3dea: FaucetOutline, // Materiais Hidráulicos
  '5ba391add4ee0d0e9163024b': CouchOutline, // Móveis
  '31f9564d0e848f47172111bd': HangerOutline, // Organização da Casa
  '5ca652f1f846aa5eaf15618b': PawOutline, // Pet Shop
  ea20efefee3df13ca161ba64: PaintBucketOutline, // Pintura e Acessórios
  ffbaf27929868c29b913d828: FloorOutline, // Pisos e Revestimentos
  '66160b2ab4c121cfa29a6268': DoorOutline, // Portas, Janelas e Portões
  c0912e548bb7ceff3911b67d: CameraSecurityOutline, // Segurança e Comunicação
  // '5dbc9a5bd07ab1130e527d58': HouseHoldItems, // Utilidades Domésticas
};

export const NO_CHILDREN_NAV_ID = 'no-children';
