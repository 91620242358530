import { Stack, Avatar, Icon, Text, Button } from '@backyard-ui/core';
import { UserOutline } from '@backyard-ui/icons';

import { useHeaderStore } from '../../../Header.store';

import * as tracker from '../../../analytics/UserMenu.tracker';

import styles from './UserMenu.styles';

export function Unauthenticated() {
  const theme = useHeaderStore((state) => state.theme);

  return (
    <div className={styles().base()}>
      <Stack
        direction="column"
        justify="flex-start"
        spacing={4}
        className="w-full"
      >
        <Stack align="center" spacing={4}>
          <Avatar.Root size="sm" appearance="info">
            <Avatar.Fallback>
              <Icon size="sm">
                <UserOutline data-testid="container-user-menu-avatar" />
              </Icon>
            </Avatar.Fallback>
          </Avatar.Root>

          <Text
            color={theme === 'default' ? 'info-900' : 'white'}
            size="lg"
            transform="capitalize"
            weight="bold"
          >
            Olá!
          </Text>
        </Stack>

        <Stack spacing="4" isGrow>
          <Button size="xs" asChild>
            <a
              href={`/login`}
              onClick={() => tracker.login({ device: 'mobile' })}
            >
              Entrar
            </a>
          </Button>
          <Button size="xs" variant="outline" asChild>
            <a
              href={`/cadastre-se`}
              onClick={() => tracker.signup({ device: 'mobile' })}
            >
              Criar conta
            </a>
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
