import type { BaseSyntheticEvent, MouseEvent, KeyboardEvent } from 'react';

import { Text, Stack, Visible } from '@backyard-ui/core';
import type {
  AutocompleteCollection,
  AutocompleteApi,
} from '@algolia/autocomplete-core';

import type { AutocompleteItem } from '../Searchbar.types';
import { formatPrice } from '../Searchbar.utilts';

import styles from '../Searchbar.styles';
import { useHeaderStore } from '../../../../Header.store';

interface ProductListProps
  extends AutocompleteCollection<AutocompleteItem>,
    Partial<
      AutocompleteApi<
        AutocompleteItem,
        BaseSyntheticEvent,
        MouseEvent,
        KeyboardEvent
      >
    > {}

export const getProductPageUrl = (url: string) => {
  const firstOrContactParam = url.includes('?') ? '&' : '?';

  return `${url.concat(firstOrContactParam)}is_recommended_product=true`;
};

function ProductList(props: ProductListProps) {
  const { source, items, getItemProps } = props;

  const region = useHeaderStore((state) => state.locationContext.region);

  return (
    <Visible above="md">
      <li
        key={source.sourceId}
        className={styles().gridProducts()}
        data-area="products"
      >
        <Text size="lg" weight="bold" asChild>
          <span>Produtos relacionados</span>
        </Text>

        <ul className={styles().list()}>
          {items.map((item) => {
            /**
             * @todo
             * This is a temporary solution to get a region to set the price of the product.
             */
            const REGIONAL_DATA = item.regionalAttributes?.[region];

            const price = REGIONAL_DATA.originalPrice;
            const promotional = REGIONAL_DATA.promotionalPrice;

            const isPromotional = Boolean(promotional) && Boolean(price);

            return (
              <li
                key={item.objectID}
                className={styles().product()}
                {...getItemProps?.({
                  item,
                  source,
                })}
              >
                <a href={getProductPageUrl(item.url)}>
                  <Stack spacing={2}>
                    <div className={styles().picture()}>
                      <img src={item.pictures.normal} alt={item.name} />
                    </div>
                    <Stack direction="column" spacing={0}>
                      <Text size="md" noOfLines={2} asChild>
                        <span>{item.name}</span>
                      </Text>

                      {isPromotional && (
                        <Text
                          size="md"
                          color="neutral-900"
                          decoration="line-through"
                          asChild
                        >
                          <span>
                            {formatPrice(price)} {item.unit}
                          </span>
                        </Text>
                      )}

                      <Text
                        color={isPromotional ? 'red-600' : 'neutral-900'}
                        weight="bold"
                        asChild
                      >
                        <span>
                          {formatPrice(promotional)} {item.unit}
                        </span>
                      </Text>
                    </Stack>
                  </Stack>
                </a>
              </li>
            );
          })}
        </ul>
      </li>
    </Visible>
  );
}

export default ProductList;
