import { tv } from 'tailwind-variants';

export default tv({
  base: `
    relative
    z-50

    antialiased
    shadow-xs
  `,
  variants: {
    theme: {
      default: 'bg-white',
      'black-weekend': 'bg-black',
    },
  },
});
