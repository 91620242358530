import { tv } from 'tailwind-variants';

export default tv({
  slots: {
    base: `
      relative
      z-50

      shadow-xs
    `,
    'top-bar': `
      py-2
    `,
    container: `
      gap-y-5

      pb-5
      pt-6
    `,
    logo: `
      [&_svg]:block
      [&_svg]:w-[55px]
      [&_svg]:lg:w-[105px]
    `,
    input: `
      relative

      order-1
      mt-4

      h-10
      w-full

      lg:order-[0]
      lg:ml-8
      lg:mr-16
      lg:mt-0
      lg:h-12
      lg:flex-1
    `,
    navigation: `
      h-full
      pb-6
    `,
  },
  variants: {
    theme: {
      default: {
        base: 'bg-white',
        'top-bar': 'bg-green-700 lg:bg-blue-900',
      },
      'black-weekend': {
        base: 'bg-black',
        'top-bar': 'bg-green-700',
      },
    },
  },
});
