import type { ChangeEvent, FormEvent } from 'react';

import {
  Button,
  FormControl,
  Label,
  TextField,
  Stack,
  Spinner,
} from '@backyard-ui/core';

import { AutoComplete } from './AutoComplete';

import { useLocationStore } from './TopBar.store';
import { useDebounce } from '../../hooks/use-debounce';
import { regex } from './TopBar.utils';
import type { Location } from './model';
import type { TopBarProps } from './TopBar';
import useGetLocationsByCityName from './useGetLocationsByCityName';
import useGetLocationByZipcode from './useGetLocationByZipcode';

interface LocationFormProps extends Pick<TopBarProps, 'locationRepository'> {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

function LocationForm(props: LocationFormProps) {
  const { onSubmit, locationRepository } = props;

  const { ui, data: dataLocationStore } = useLocationStore();

  const debouncedCityName = useDebounce(dataLocationStore.city, 400);

  const handleChangeCity = (value: string) => {
    if (value !== dataLocationStore.city) {
      dataLocationStore.setCity(value);
    }
  };

  const handleChangeZipCode = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    inputValue = inputValue
      .replace(regex.numeric, '')
      .replace(regex.zipCode, '$1-$2');

    if (inputValue.length <= 9) {
      if (dataLocationStore.zipCode !== inputValue) {
        dataLocationStore.setZipCode(inputValue);
      }
    }
  };

  const handleLocationSelected = (value: Location | null) => {
    if (value) {
      dataLocationStore.setLocation(value);
    }
  };

  const cityQuery = useGetLocationsByCityName(
    debouncedCityName,
    locationRepository.getByCityName,
    {
      enabled:
        debouncedCityName.length >= 3 &&
        dataLocationStore.city === debouncedCityName &&
        !dataLocationStore.location?.city,
      refetchOnWindowFocus: false,
    },
  );

  const zipcodeQuery = useGetLocationByZipcode(
    dataLocationStore.zipCode,
    locationRepository.getByZipcode,
    {
      enabled: dataLocationStore.zipCode.length > 8,
      refetchOnWindowFocus: false,
      onSuccess: (data) => dataLocationStore.setLocation(data),
    },
  );

  return (
    <form onSubmit={onSubmit}>
      <Stack direction="column" spacing={6}>
        <FormControl.Root isInvalid={cityQuery.isError}>
          <AutoComplete
            inputValue={dataLocationStore.city}
            items={cityQuery.data?.map((item) => ({
              ...item,
            }))}
            onInputValueChange={handleChangeCity}
            onSelect={handleLocationSelected}
            onClear={() => dataLocationStore.setCity('')}
            isDisabled={Boolean(dataLocationStore.location?.city)}
            isLoading={cityQuery.isFetching}
          />

          <FormControl.ErrorMessage>
            Cidade não encontrada
          </FormControl.ErrorMessage>
        </FormControl.Root>

        <FormControl.Root isInvalid={zipcodeQuery.isError}>
          <Label>Buscar por CEP</Label>
          <TextField.Root>
            <TextField.Input
              name="zipcode"
              placeholder="Digite o CEP"
              value={dataLocationStore.zipCode}
              onChange={handleChangeZipCode}
              aria-autocomplete="none"
              autoComplete="none"
            />
            {zipcodeQuery.isFetching && (
              <TextField.Slot>
                <Spinner />
              </TextField.Slot>
            )}
          </TextField.Root>
          <FormControl.ErrorMessage>
            CEP não encontrado
          </FormControl.ErrorMessage>
        </FormControl.Root>

        <Button
          type="submit"
          isFullWidth
          isDisabled={
            !dataLocationStore.location?.region || ui.isLoadingGeoLocation
          }
        >
          Confirmar
        </Button>
      </Stack>
    </form>
  );
}

export default LocationForm;
