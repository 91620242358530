import {
  createLocalStorageRecentSearchesPlugin,
  search,
} from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createRedirectUrlPlugin } from '@algolia/autocomplete-plugin-redirect-url';
import { createAlgoliaInsightsPlugin } from '@algolia/autocomplete-plugin-algolia-insights';
import type { LiteClient as SearchClient } from 'algoliasearch/lite';

import { sendGTMEvent } from '../../../utils/sendGTMEvent';
import { AutocompleteItem } from './Searchbar.types';

export interface PluginsOptions {
  /**
   *
   */
  insightsClient: any;

  /**
   *
   */
  searchClient: SearchClient;

  /**
   *
   */
  indexName: string;

  /**
   *
   */
  retailMediaIndexName?: string;
}

export function plugins(opts: PluginsOptions, region: string): any {
  const { insightsClient, searchClient, indexName } = opts;

  return {
    recents: createLocalStorageRecentSearchesPlugin({
      key: 'Recent Searches',
      limit: 4,
      search(params) {
        return search({
          ...params,
          items: params.items.slice(0, params.limit).filter((item) => {
            if (item.label.trim().length) {
              return {
                ...params,
                item,
              };
            }

            return undefined;
          }),
        });
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'recentSearchesPlugin',
          getItemInputValue({ item }) {
            return item.label;
          },
          onSelect({ setIsOpen, state }) {
            sendGTMEvent({
              value: {
                search: {
                  term: state.query,
                  type: 'recentSearch',
                },
              },
            });

            setIsOpen(false);
          },
        };
      },
    }),
    suggestions: createQuerySuggestionsPlugin({
      searchClient,
      indexName: `${indexName}_query_suggestions`,
      getSearchParams({ state }) {
        return {
          hitsPerPage: state.query ? 10 : 5,
        };
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect({ setIsOpen, state }) {
            sendGTMEvent({
              value: {
                search: {
                  term: state.query,
                  type: 'suggestSearch',
                },
              },
            });

            setIsOpen(false);
          },
        };
      },
    }),
    insights: createAlgoliaInsightsPlugin({
      insightsClient,
      onItemsChange({ insights, insightsEvents }) {
        const events = insightsEvents.map((insightsEvent) => ({
          ...insightsEvent,
          eventName: 'ProductViewedfromAutocomplete',
        }));

        insights.viewedObjectIDs(...events);
      },
      onSelect({ insights, insightsEvents }) {
        const events = insightsEvents.map((insightsEvent) => ({
          ...insightsEvent,
          eventName: 'ProductSelectedfromAutocomplete',
        }));

        insights.clickedObjectIDsAfterSearch(...events);

        const hit = events[0];

        const hitObj = {
          queryID: hit.queryID,
          indexName: hit.index,
          position: hit.positions[0],
          objectID: hit.items[0].objectID,
          price: (hit.items[0] as unknown as AutocompleteItem)
            ?.regionalAttributes?.[region]?.promotionalPrice,
          currency: 'BRL',
          quantity: 1,
        };

        localStorage.setItem('__algoliaInsights__', JSON.stringify(hitObj));
      },
    }),
    redirect: createRedirectUrlPlugin(),
  };
}
