import {
  List,
  Item,
  Link,
  Content,
  Sub,
} from '@radix-ui/react-navigation-menu';
import { Skeleton } from '@backyard-ui/core';
import { useMediaQuery } from '@backyard-ui/hooks';

import { BackButton } from './BackButton';

import { useHeaderStore } from '../../Header.store';
import { useNavigationStore } from './Navigation.store';

import * as tracker from './Navigation.tracker';

import type { NavigationRepository, SubCategory } from './model';
import styles from './Navigation.styles';
import useGetChildrenById from './useChildrenById';

interface SubCategoriesProps {
  category: SubCategory;
  parentCategory: string;
  navigationRepository: NavigationRepository;
}

const SHELL_AMOUNT = 6;
const SHELL_ITEMS = Array(SHELL_AMOUNT).fill('');

export function SubCategories(props: SubCategoriesProps) {
  const { category, parentCategory, navigationRepository } = props;

  const isDesktop = useMediaQuery('lg');

  const theme = useHeaderStore((state) => state.theme);
  const { currentCategory } = useNavigationStore();
  const { data, isInitialLoading, isFetching } = useGetChildrenById(
    category.id!,
    navigationRepository.getChildrenById,
    {
      staleTime: Infinity,
      enabled: Boolean(category.hasChildren && currentCategory === category.id),
    },
  );

  return (
    <Content
      className={styles({
        hasSub: true,
      }).content({ theme })}
    >
      <BackButton name={category.name} />

      <Sub orientation="vertical">
        <List className={styles().subList()}>
          {isInitialLoading || isFetching
            ? SHELL_ITEMS.map((_, index) => (
                <Item key={index} className={styles().item()}>
                  <div className={styles().sub({ theme })}>
                    <Skeleton height="19px" />
                  </div>
                </Item>
              ))
            : data?.map(({ id, name, url }) => (
                <Item key={`${name}-${id}`} value={id}>
                  <Link
                    className={styles({
                      hasChildren: true,
                    }).sub({ theme })}
                    aria-label={name}
                    href={url}
                    onClick={() =>
                      tracker.navigation({
                        device: isDesktop ? 'desktop' : 'mobile',
                        id: id || 'NO_ID',
                        originMenuName: parentCategory,
                        tree: `${parentCategory} - ${category.name} - ${name}`,
                      })
                    }
                  >
                    <span>{name}</span>
                  </Link>
                </Item>
              ))}
        </List>
      </Sub>
    </Content>
  );
}
