import { useStoreWithEqualityFn } from 'zustand/traditional';
import { createStore } from 'zustand';
import type { StoreApi } from 'zustand';
import { shallow } from 'zustand/shallow';

import { createContext } from '@backyard-ui/utils';

import type { User, LocationContext } from './model';

export type HeaderState = {
  /**
   * User `data` and permissions
   */
  user?: User;

  /**
   * User location data
   */
  locationContext: LocationContext;

  /**
   * Cart items quantity
   */
  cartItems: number;

  /**
   * Theme `data`
   */
  theme?: 'default' | 'black-weekend';

  /**
   * Only UI state
   */
  ui: {
    /**
     * Verify if menu is open
     */
    isMenuOpen: boolean;
  };

  /**
   * Enforce user redirect when navigating
   * outside algolia context
   */
  routing?: boolean;
};

type Actions = {
  /**
   * Toggle menu state
   */
  toggleMenu?: () => void;
};

const createHeaderStore = (initialState: HeaderState) => {
  const { ui, user, locationContext, cartItems, theme, routing } = initialState;

  return createStore<HeaderState>()((set) => ({
    ...initialState,
    ui,
    user,
    locationContext,
    cartItems,
    theme,
    routing,
    toggleMenu: () =>
      set((state) => ({
        ...state,
        ui: {
          ...state.ui,
          isMenuOpen: !state.ui.isMenuOpen,
        },
      })),
  }));
};

const { Provider: HeaderProvider, useContext: useHeaderContext } =
  createContext<StoreApi<HeaderState>>({
    name: '@store/Header',
  });

const useHeaderStore = <T>(
  selector: (state: HeaderState & Actions) => T,
  equalityFn: (a: any, b: any) => boolean = shallow,
) => {
  const context = useHeaderContext();

  return useStoreWithEqualityFn(
    context as StoreApi<HeaderState & Actions>,
    selector,
    equalityFn,
  );
};

export { HeaderProvider, useHeaderStore, createHeaderStore };
