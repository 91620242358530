import { HTMLAttributes } from 'react';
import { Icon, Visible, DropdownMenu } from '@backyard-ui/core';

import { Authenticated } from './Authenticated';
import { Unauthenticated } from './Unauthenticated';

import { useHeaderStore } from '../../../../Header.store';

import { Loading } from './UserMenu.loading';

import styles from '../Actions.styles';

interface UserMenuProps extends HTMLAttributes<HTMLButtonElement> {}

function UserMenu(props: UserMenuProps) {
  const { user, theme } = useHeaderStore((state) => ({
    user: state.user,
    theme: state.theme,
  }));

  const getMenu = () => {
    if (!user) {
      return <Loading />;
    }

    return user?.isAuthenticated ? <Authenticated /> : <Unauthenticated />;
  };

  return (
    <Visible above="lg">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild {...props}>
          <button aria-label="Entrar" className={styles().item({ theme })}>
            <Icon
              color="current"
              size={{
                initial: 'lg',
                lg: '2xl',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 30 33"
              >
                <path
                  fill="currentColor"
                  d="M28.723 26.812a15.046 15.046 0 0 0-3.2-4.804 14.912 14.912 0 0 0-4.747-3.24c-.016-.008-.032-.012-.047-.02a9.986 9.986 0 0 0 4.082-8.08c0-5.508-4.408-9.97-9.85-9.97-5.44 0-9.849 4.462-9.849 9.97a10 10 0 0 0 4.083 8.084c-.016.008-.032.012-.048.02-1.779.76-3.376 1.849-4.746 3.24a15.113 15.113 0 0 0-3.2 4.804 15.098 15.098 0 0 0-1.172 5.552.326.326 0 0 0 .194.304c.039.017.08.025.123.025H2.73c.175 0 .314-.14.318-.313.08-3.104 1.31-6.01 3.487-8.213 2.252-2.28 5.243-3.534 8.428-3.534 3.185 0 6.176 1.254 8.428 3.534a12.056 12.056 0 0 1 3.487 8.213.316.316 0 0 0 .317.313h2.383a.314.314 0 0 0 .228-.097.322.322 0 0 0 .09-.232 15.137 15.137 0 0 0-1.172-5.556Zm-13.761-9.23a6.746 6.746 0 0 1-4.83-2.026 6.913 6.913 0 0 1-2.001-4.889c0-1.845.71-3.582 2.001-4.888a6.746 6.746 0 0 1 4.83-2.026c1.823 0 3.538.72 4.83 2.026a6.912 6.912 0 0 1 2 4.888c0 1.845-.71 3.582-2 4.889a6.746 6.746 0 0 1-4.83 2.026Z"
                />
              </svg>
            </Icon>
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content align="center">{getMenu()}</DropdownMenu.Content>
      </DropdownMenu.Root>
    </Visible>
  );
}

export default UserMenu;
