import React from 'react';
import type { HTMLAttributes, ReactNode } from 'react';

import { HeaderProvider, createHeaderStore } from './Header.store';
import type { HeaderState } from './Header.store';

import { shallowEqual } from 'fast-equals';

import styles from './Header.styles';

export interface HeaderProps
  extends HTMLAttributes<HTMLDivElement>,
    Omit<HeaderState, 'ui'> {
  children: ReactNode;
}

function Header(props: HeaderProps) {
  const {
    user,
    locationContext,
    cartItems,
    theme = 'default',
    routing = false,
    className,
    ...rest
  } = props;

  const store = React.useRef<ReturnType<typeof createHeaderStore>>();

  if (!store.current) {
    store.current = createHeaderStore({
      user,
      locationContext,
      cartItems,
      theme,
      routing,
      ui: { isMenuOpen: false },
    });
  }

  React.useEffect(() => {
    if (!shallowEqual(store.current?.getState().user, user)) {
      store.current?.setState({ user });
    }
  }, [user]);

  React.useEffect(() => {
    if (
      !shallowEqual(store.current?.getState().locationContext, locationContext)
    ) {
      store.current?.setState({ locationContext });
    }
  }, [locationContext]);

  React.useEffect(() => {
    if (!shallowEqual(store.current?.getState().cartItems, cartItems)) {
      store.current?.setState({ cartItems });
    }
  }, [cartItems]);

  return (
    <HeaderProvider value={store.current}>
      <header className={styles({ theme, className })} {...rest} />
    </HeaderProvider>
  );
}

export default React.memo(Header);
