import React from 'react';

import { Hidden } from '@backyard-ui/core';
import { ChevronLeft } from '@backyard-ui/icons';
import { Item, Trigger } from '@radix-ui/react-navigation-menu';

import { useHeaderStore } from '../../../Header.store';

import type { Category } from '../model';
import styles from './BackButton.styles';

export interface BackButtonProps {
  name: Category['name'];
}

function BackButton(props: BackButtonProps) {
  const { name } = props;

  const theme = useHeaderStore((state) => state.theme);

  return (
    <Hidden above="lg">
      <div className={styles().base({ theme })}>
        <Item className={styles().trigger({ theme })} asChild>
          <Trigger>
            <ChevronLeft />
            {name}
          </Trigger>
        </Item>
      </div>
    </Hidden>
  );
}

export default React.memo(BackButton);
