import type { BaseSyntheticEvent, MouseEvent, KeyboardEvent } from 'react';

import { Text, Stack, Icon, Spacer } from '@backyard-ui/core';
import { SearchOutline } from '@backyard-ui/icons';

import { parseAlgoliaHitHighlight } from '@algolia/autocomplete-preset-algolia';
import type { HighlightedHit } from '@algolia/autocomplete-preset-algolia';
import type {
  AutocompleteCollection,
  AutocompleteApi,
} from '@algolia/autocomplete-core';

import { useHeaderStore } from '../../../../Header.store';
import type { AutocompleteItem } from '../Searchbar.types';

import styles from '../Searchbar.styles';
import { sendGTMEvent } from '../../../../utils/sendGTMEvent';

interface QuerySuggestionsProps
  extends AutocompleteCollection<AutocompleteItem>,
    Partial<
      AutocompleteApi<
        AutocompleteItem,
        BaseSyntheticEvent,
        MouseEvent,
        KeyboardEvent
      >
    > {
  /**
   * The query from the last search.
   */
  query: string;

  /**
   * Sets a new query and searches.
   */
  refine: (value: string) => void;
}

function Highlight(props: HighlightedHit<any>) {
  const { hit, attribute, tagName = 'strong' } = props;
  const Tag = tagName;

  return (
    <>
      {parseAlgoliaHitHighlight({ hit, attribute }).map(
        ({ value, isHighlighted }, index) =>
          isHighlighted ? <Tag key={index}>{value}</Tag> : value,
      )}
    </>
  );
}

function QuerySuggestions(props: QuerySuggestionsProps) {
  const {
    query,
    items,
    source,
    refine,
    refresh,
    getItemProps,
    setIsOpen,
    setQuery,
  } = props;

  const hasItems = Boolean(items.length);

  const routing = useHeaderStore((state) => state.routing);

  const getItem = (options: { item?: AutocompleteItem; query?: string }) => {
    const { item, query } = options;
    const getQuery = item?.query || query;

    if (!getQuery) {
      return null;
    }

    return (
      <li
        key={item?.objectID || getQuery}
        className={styles().suggestion()}
        {...(hasItems && item?.query && getItemProps?.({ item, source }))}
      >
        <Stack align="center" spacing={2}>
          <Text align="left" size="lg" noOfLines={1} asChild>
            {!routing ? (
              <span
                role="button"
                className="w-full"
                tabIndex={0}
                onClick={() => {
                  setIsOpen?.(false);

                  refine(getQuery);
                  refresh?.();
                  if (!hasItems) {
                    sendGTMEvent({
                      value: {
                        search: {
                          term: getQuery,
                          type: 'suggestSearch',
                        },
                      },
                    });
                  }
                }}
                onKeyDown={(event) => {
                  event.preventDefault();

                  if (event.key === 'Enter' || event.key === ' ') {
                    setQuery?.(getQuery);
                    setIsOpen?.(false);

                    refine(getQuery);
                    refresh?.();
                  }
                }}
              >
                <Icon size="xs">
                  <SearchOutline />
                </Icon>
                <Spacer size={2} />
                {hasItems ? (
                  <Highlight hit={item} attribute="query" />
                ) : (
                  getQuery
                )}
              </span>
            ) : (
              <a
                href={`/search?term=${getQuery}&searchTerm=${getQuery}&searchType=default`}
              >
                <Icon size="xs">
                  <SearchOutline />
                </Icon>
                <Spacer size={2} />
                {hasItems ? (
                  <Highlight hit={item} attribute="query" />
                ) : (
                  getQuery
                )}
              </a>
            )}
          </Text>
        </Stack>
      </li>
    );
  };

  return (
    <li key={source.sourceId} data-area="suggestions">
      <Text size="lg" weight="bold" asChild>
        <span>{hasItems ? 'Sugestões' : 'Termo Buscado'}</span>
      </Text>

      <ul className={styles().list()}>
        {hasItems ? items.map((item) => getItem({ item })) : getItem({ query })}
      </ul>
    </li>
  );
}

export default QuerySuggestions;
