import type { BaseSyntheticEvent, MouseEvent, KeyboardEvent } from 'react';

import { Text, Stack, Icon, CloseButton, Spacer } from '@backyard-ui/core';
import { SearchOutline } from '@backyard-ui/icons';

import type {
  AutocompleteCollection,
  AutocompleteApi,
} from '@algolia/autocomplete-core';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';

import { useHeaderStore } from '../../../../Header.store';
import type { AutocompleteItem } from '../Searchbar.types';

import styles from '../Searchbar.styles';

interface RecentsSearchProps
  extends AutocompleteCollection<AutocompleteItem>,
    Partial<
      AutocompleteApi<
        AutocompleteItem,
        BaseSyntheticEvent,
        MouseEvent,
        KeyboardEvent
      >
    >,
    ReturnType<typeof createLocalStorageRecentSearchesPlugin> {
  /**
   * Sets a new query and searches.
   */
  refine: (value: string) => void;
}

function RecentsSearch(props: RecentsSearchProps) {
  const {
    source,
    items,
    data,
    refine,
    refresh,
    getItemProps,
    setIsOpen,
    setQuery,
  } = props;

  const routing = useHeaderStore((state) => state.routing);

  return (
    <li
      key={source.sourceId}
      className={styles().gridRecents()}
      data-area="recents"
    >
      <Text size="lg" weight="bold" asChild>
        <span>Buscas Recentes</span>
      </Text>

      <ul className={styles().list()}>
        {items.map((item, index) => {
          return (
            <li
              key={`${item.id}-${index}`}
              className={styles().suggestion()}
              {...getItemProps?.({
                item,
                source,
              })}
            >
              <Stack align="center" justify="space-between" spacing={2}>
                <Text size="lg" noOfLines={1} asChild>
                  {!routing ? (
                    <span
                      role="button"
                      className="w-full"
                      tabIndex={0}
                      onClick={() => {
                        if (item?.label) {
                          refine(item.label);

                          setQuery?.(item.label);
                          setIsOpen?.(false);

                          refresh?.();
                        }
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                          event.preventDefault();
                          event.stopPropagation();

                          if (item?.query) {
                            setQuery?.(item.query);
                            setIsOpen?.(false);

                            refine(item.query);
                            refresh?.();
                          }
                        }
                      }}
                    >
                      <Icon size="xs">
                        <SearchOutline />
                      </Icon>
                      <Spacer size={2} />
                      {item.label}
                    </span>
                  ) : (
                    <a
                      href={`/search?term=${item.label}&searchTerm=${item.label}&searchType=default`}
                    >
                      <Icon size="xs">
                        <SearchOutline />
                      </Icon>
                      <Spacer size={2} />
                      {item.label}
                    </a>
                  )}
                </Text>

                <CloseButton
                  type="button"
                  size="xs"
                  aria-label={`Remover ${item.label} da busca recente`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    data?.removeItem(item.id);
                    refresh?.();
                  }}
                />
              </Stack>
            </li>
          );
        })}
      </ul>
    </li>
  );
}

export default RecentsSearch;
