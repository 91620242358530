import { create } from 'zustand';
import { Location } from './model';

interface LocationState {
  ui: {
    isModalOpen: boolean;
    isLoadingGeoLocation: boolean;
  };

  data: {
    location?: Location;
    city: string;
    zipCode: string;
  };
}

type LocationActions = LocationState & {
  ui: {
    toggleModal: () => void;
    toggleGeoLocation: () => void;
  };

  data: {
    setLocation: (value: Location) => void;
    setCity: (value: string) => void;
    setZipCode: (value: string) => void;
  };
};

export const useLocationStore = create<LocationActions>((set) => ({
  ui: {
    isModalOpen: false,
    isLoadingGeoLocation: false,
    toggleModal: () =>
      set((state) => ({
        ui: {
          ...state.ui,
          isModalOpen: !state.ui.isModalOpen,
        },
        data: {
          ...state.data,
          zipCode: '',
          city: '',
          location: undefined,
        },
      })),

    toggleGeoLocation: () =>
      set((state) => ({
        ui: {
          ...state.ui,
          isLoadingGeoLocation: !state.ui.isLoadingGeoLocation,
        },
      })),
  },
  data: {
    city: '',
    zipCode: '',
    setLocation: (value) =>
      set((state) => ({
        data: {
          ...state.data,
          location: value,
          city: `${value.city} - ${value.state}`,
        },
      })),

    setCity: (value) =>
      set((state) => ({
        data: {
          ...state.data,
          city: value,
          zipCode: '',
          location: undefined,
        },
      })),

    setZipCode: (value) =>
      set((state) => ({
        data: {
          ...state.data,
          city: '',
          zipCode: value,
          location: undefined,
        },
      })),
  },
}));
