import { Facebook } from '@backyard-ui/icons';

export const apple = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 35.676 35.676"
    fill="#FFFFFF"
  >
    <path d="M32.295 26.202c-.096.271-.189.562-.296.848-.886 2.399-2.231 4.529-3.863 6.481-.315.38-.682.724-1.061 1.043-.749.634-1.611 1.017-2.608 1.052-.749.024-1.468-.112-2.161-.394-.502-.205-.996-.434-1.505-.619-1.652-.6-3.295-.521-4.92.121-.586.232-1.164.488-1.761.689-.692.232-1.41.326-2.141.188-.638-.119-1.196-.416-1.714-.799-.643-.476-1.183-1.056-1.688-1.67-2.391-2.916-3.996-6.213-4.771-9.906-.334-1.588-.494-3.189-.396-4.812.115-1.946.567-3.799 1.607-5.469 1.305-2.099 3.146-3.474 5.568-4.041 1.457-.343 2.874-.203 4.263.332.731.28 1.464.557 2.198.832.676.254 1.349.254 2.026-.005.749-.286 1.499-.571 2.251-.85.771-.281 1.555-.511 2.373-.576 1.224-.099 2.418.06 3.58.449 1.647.551 2.987 1.526 3.999 2.946.027.039.057.082.077.113-2.552 1.779-4.005 4.129-3.794 7.311.213 3.184 1.927 5.338 4.737 6.736zM17.98 8.253c.753.02 1.477-.125 2.174-.402 3.179-1.262 4.841-4.625 4.791-7.197-.004-.207-.018-.414-.027-.654-.327.049-.625.072-.911.144-2.321.569-4.107 1.864-5.281 3.961-.687 1.228-1.069 2.532-.952 3.957.008.151.063.189.206.191z" />
  </svg>
);

export const google = (
  <svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 300 300"
  >
    <g>
      <path
        fillRule="evenodd"
        fill="#D7282A"
        d="M19.2,82.7C25.3,69.3,34,57.5,44.1,46.9C67,22.9,94.8,7.5,127.7,2.2
            c46-7.4,87.2,3.5,122.8,34.2c2.2,1.9,2.8,3.1,0.4,5.5c-12.6,12.3-24.9,24.8-37.4,37.2c-1.3,1.3-2.1,2.8-4.4,0.8
            c-31.3-28.7-82.5-28.4-115.8,2.6c-11.4,10.6-19.6,23.4-24.9,38c-0.8-0.5-1.6-1-2.3-1.5C50.5,106.8,34.8,94.8,19.2,82.7z"
      />
      <path
        fillRule="evenodd"
        fill="#45AC43"
        d="M68,179c4.5,11.4,10.1,22.2,18.4,31.4c21.1,23.3,47.2,33.3,78.6,30.1
            c14.6-1.5,27.8-6.4,40.2-14c1.2,1.1,2.3,2.2,3.6,3.2c14.5,11.3,29,22.6,43.5,33.9c-16,15.2-34.9,25.3-56.2,30.7
            C146,307,99.8,298.9,58.8,266.6c-17-13.4-30.3-29.9-39.7-49.5C35.4,204.4,51.7,191.7,68,179z"
      />
      <path
        fillRule="evenodd"
        fill="#5D7FBE"
        d="M252.3,263.6c-14.5-11.3-29-22.6-43.5-33.9c-1.3-1-2.4-2.1-3.6-3.2
            c9.8-7.5,18-16.4,23.4-27.6c2.1-4.5,3.7-9.2,5.1-14c1-3.3,0.7-4.6-3.4-4.5c-24.2,0.2-48.3,0.1-72.5,0.1c-5.1,0-5.1,0-5.1-5.3
            c0-16.4,0.1-32.8-0.1-49.1c0-3.2,0.5-4.4,4.1-4.4c44.6,0.1,89.1,0.1,133.7,0c2.4,0,3.9,0.2,4.3,3.1c5.5,39,1.1,76.2-19.3,110.6
            C269.2,246,261.7,255.6,252.3,263.6z"
      />
      <path
        fillRule="evenodd"
        fill="#F4C300"
        d="M68,179c-16.3,12.7-32.7,25.4-49,38c-8-14.8-12.6-30.7-14.7-47.3
            C0.6,141,4.7,113.3,16.9,87c0.7-1.4,1.5-2.8,2.3-4.2c15.6,12,31.3,24.1,46.9,36.1c0.7,0.6,1.5,1,2.3,1.5
            C61.9,139.9,62.2,159.4,68,179z"
      />
    </g>
  </svg>
);

export const facebook = <Facebook />;
