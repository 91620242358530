import { sendGTMEvent } from '../../utils/sendGTMEvent';

interface NavigationProps {
  originMenuName: string;
  tree: string;
  device: 'mobile' | 'desktop';
  id: string;
}

export const navigation = (data: NavigationProps) => {
  const { originMenuName, tree, device, id } = data;

  const EVENT_OBJECT = {
    element: device === 'mobile' ? 'data-mobile-menu' : 'data-desktop-menu',
    menuName: tree,
    menuId: id,
    originMenuName,
  };

  sendGTMEvent({ event: 'menuInteraction', value: EVENT_OBJECT });
};
