import type { LocationContext } from '../../model';
import { sendGTMEvent } from '../../utils/sendGTMEvent';

interface LocationProps {
  type: 'coordinates' | 'city' | 'zipCode';
  regionName?: string;
  zipCode?: string;
}

interface PopoverProps {
  type: 'show' | 'confirm' | 'change';
  regionName?: LocationContext['regionName'];
  isAutomaticShow: boolean;
  locationSource?: LocationContext['locationSource'];
}

export const location = (data: LocationProps) => {
  const { type, regionName, zipCode } = data;

  const DIMENSION_DICTIONARY = {
    coordinates: { dimension60: 'Selecionou localização automática' },
    city: { dimension61: regionName },
    zipCode: { dimension62: zipCode },
  };

  const EVENT_OBJECT = {
    ...DIMENSION_DICTIONARY[type],
  };

  sendGTMEvent({
    event: 'localizacao-automatica-alterada',
    value: EVENT_OBJECT,
  });
};

export const popover = (data: PopoverProps) => {
  const { type, regionName, isAutomaticShow, locationSource } = data;

  const EVENT_MAP = {
    show: 'popover-localizacao',
    confirm: 'popover-localizacao-confirmar',
    change: 'popover-localizacao-trocar',
  };

  const EVENT_OBJECT = {
    dimension60: regionName,
    displayAction: isAutomaticShow ? 'first-access-automatic' : 'user-click',
    regionSource:
      locationSource === 'cloudflare' ? 'service-automatic' : 'user-manual',
  };

  sendGTMEvent({ event: EVENT_MAP[type], value: EVENT_OBJECT });
};
