import { isBrowser } from '@backyard-ui/utils';

export const getValue = (name: string, initialValue?: any) => {
  if (!isBrowser) {
    return initialValue;
  }

  try {
    const item = localStorage.getItem(name);

    return item ? JSON.parse(item) : null;
  } catch (error) {
    return initialValue;
  }
};

export const setValue = (name: string, value: any) => {
  if (!isBrowser) {
    return;
  }

  try {
    const stringValue = JSON.stringify(value);

    localStorage.setItem(name, stringValue);

    return true;
  } catch (error) {
    return false;
  }
};

export const has = (key: string) => {
  if (!isBrowser) {
    return false;
  }

  return localStorage.getItem(key) !== null;
};

export const remove = (key: string) => {
  if (!isBrowser) {
    return;
  }

  return localStorage.removeItem(key);
};

export const removeAll = (text: string) => {
  if (!isBrowser) {
    return;
  }

  Object.keys(localStorage)
    .filter((key) => key.includes(text))
    .forEach((key) => localStorage.removeItem(key));
};
