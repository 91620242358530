import React from 'react';

import {
  List,
  Item,
  Trigger,
  Link,
  Content,
  Sub,
} from '@radix-ui/react-navigation-menu';
import { useMediaQuery } from '@backyard-ui/hooks';
import { ChevronRight } from '@backyard-ui/icons';

import { SubCategories } from './SubCategories';

import { BackButton } from './BackButton';

import { getDepartmentIcon } from '../Icons';
import { useNavigationStore } from './Navigation.store';
import { useHeaderStore } from '../../Header.store';

import * as tracker from './Navigation.tracker';

import type { Category, NavigationRepository } from './model';
import styles from './Navigation.styles';

interface CategoriesProps {
  data: Category['items'];
  name: string;
  navigationRepository: NavigationRepository;
}

export const Categories = React.memo(function Categories(
  props: CategoriesProps,
) {
  const { data, name: parentCategory, navigationRepository } = props;

  const theme = useHeaderStore((state) => state.theme);
  const { setCurrentCategory } = useNavigationStore();
  const isDesktop = useMediaQuery('lg');

  return (
    <Content className={styles().content({ theme })}>
      <BackButton name={parentCategory} />

      <Sub
        orientation={isDesktop ? 'horizontal' : 'vertical'}
        onValueChange={setCurrentCategory}
      >
        <List className={styles().subList()}>
          {data?.map(({ id, name, url, hasChildren }) => {
            const hasIcon = Boolean(
              hasChildren && getDepartmentIcon(id as string),
            );

            const getIcon =
              hasIcon && React.createElement(getDepartmentIcon(id as string));

            return (
              <Item key={`${name}-${id}`} value={id}>
                {hasChildren ? (
                  <Trigger
                    className={styles().sub({ theme })}
                    aria-label={name}
                    onClick={() =>
                      tracker.navigation({
                        device: isDesktop ? 'desktop' : 'mobile',
                        id: id || 'NO_ID',
                        originMenuName: parentCategory,
                        tree: `${parentCategory} - ${name}`,
                      })
                    }
                  >
                    {getIcon}
                    <span>{name}</span>
                    {hasChildren && !isDesktop && <ChevronRight />}
                  </Trigger>
                ) : (
                  <Link
                    href={url}
                    className={styles().sub({ theme })}
                    aria-label={name}
                    onClick={() =>
                      tracker.navigation({
                        device: isDesktop ? 'desktop' : 'mobile',
                        id: id || 'NO_ID',
                        originMenuName: parentCategory,
                        tree: `${parentCategory} - ${name}`,
                      })
                    }
                  >
                    <span>{name}</span>
                  </Link>
                )}

                {hasChildren && (
                  <SubCategories
                    category={{ id, name, hasChildren }}
                    parentCategory={parentCategory}
                    navigationRepository={navigationRepository}
                  />
                )}
              </Item>
            );
          })}
        </List>
      </Sub>
    </Content>
  );
});
