import type { PropsWithChildren } from 'react';
import { Hidden } from '@backyard-ui/core';
import { useMediaQuery } from '@backyard-ui/hooks';

import { Presence } from '@radix-ui/react-presence';
import { RemoveScroll } from 'react-remove-scroll';

import { useHeaderStore } from '../../../Header.store';

import styles from './Overlay.styles';

interface OverlayProps extends PropsWithChildren {}

function Overlay(props: OverlayProps) {
  const { children } = props;

  const { isMenuOpen, toggleMenu } = useHeaderStore((state) => ({
    isMenuOpen: state.ui.isMenuOpen,
    toggleMenu: state.toggleMenu,
  }));
  const isDesktop = useMediaQuery('lg');

  return (
    <RemoveScroll allowPinchZoom enabled={!isDesktop && isMenuOpen}>
      <Hidden above="lg">
        <Presence present={isMenuOpen}>
          <div
            date-state={isMenuOpen ? 'open' : 'closed'}
            aria-hidden="true"
            className={styles()}
            onClick={toggleMenu}
          />
        </Presence>
      </Hidden>

      {children}
    </RemoveScroll>
  );
}

export default Overlay;
