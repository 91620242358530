import { tv } from 'tailwind-variants';

export default tv({
  slots: {
    base: `
      relative
      z-50

      py-4
      shadow-xs

      lg:border-t-8
    `,
    logo: `
      [&_svg]:block
      [&_svg]:w-[55px]
      [&_svg]:lg:w-[105px]
    `,
  },
  variants: {
    theme: {
      default: {
        base: 'border-green-700 bg-white lg:border-blue-900',
      },
      'black-weekend': {
        base: 'border-green-700 bg-black',
      },
    },
  },
});
