import { tv } from 'tailwind-variants';

export default tv({
  base: '',
  slots: {
    item: `
      relative

      flex
      items-center

      outline-none

      [&_svg]:transition-colors
      [&_svg]:duration-150
      [&_svg]:ease-in

      [&_svg]:hover:text-green-600
      [&_svg]:focus:text-green-600
    `,
  },
  variants: {
    hasItems: {
      true: {
        item: `
          mr-4

          after:absolute
          after:-top-2
          after:left-4

          after:rounded-full
          after:border-2
          after:border-white
          after:bg-tertiary-700

          after:px-[7px]
          after:py-1

          after:text-sm
          after:font-bold

          after:leading-none
          after:text-white

          after:content-[attr(data-items)]

          lg:mr-2
        `,
      },
    },
    theme: {
      default: {
        item: 'text-info-900',
      },
      'black-weekend': {
        item: 'text-white',
      },
    },
  },
});
