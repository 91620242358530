import React from 'react';
import {
  Label,
  TextField,
  Spinner,
  ScrollArea,
  CloseButton,
} from '@backyard-ui/core';

import Downshift from 'downshift';
import type { DownshiftProps } from 'downshift';

import styles from './AutoComplete.styles';
import { Location } from '../model';

export interface AutoCompleteProps extends DownshiftProps<Location> {
  /**
   *
   */
  items: Array<Location> | undefined;

  /**
   *
   */
  isDisabled?: boolean;

  /**
   *
   */
  isLoading: boolean;

  /**
   *
   */
  onClear?: () => void;
}

function AutoComplete(props: AutoCompleteProps) {
  const { isDisabled, isLoading, items, onClear, ...rest } = props;

  return (
    <Downshift
      itemToString={(item) =>
        item ? `${item.city} - ${item.state}` : rest.inputValue ?? ''
      }
      {...rest}
    >
      {({
        inputValue,
        isOpen,
        highlightedIndex,
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        getLabelProps,
      }) => {
        const canOpen = isOpen && !isLoading && items?.length && inputValue;

        return (
          <React.Fragment>
            <Label {...getLabelProps()}>Buscar por cidade</Label>
            <TextField.Root
              {...getRootProps(
                {},
                {
                  suppressRefError: true,
                },
              )}
            >
              <TextField.Input
                name="city"
                placeholder="Digite o nome da cidade"
                aria-autocomplete="none"
                autoComplete="none"
                data-1p-ignore
                isDisabled={isDisabled}
                {...getInputProps()}
              />
              <div
                className={styles().list()}
                {...getMenuProps({
                  'data-state': canOpen ? 'open' : 'closed',
                })}
              >
                <ScrollArea>
                  <div className={styles().scroll()}>
                    {canOpen
                      ? items?.map((item, index) => (
                          <div
                            {...getItemProps({
                              key: item.id,
                              index,
                              item,
                              className: styles().item(),
                              'data-state':
                                highlightedIndex === index
                                  ? 'active'
                                  : undefined,
                            })}
                          >
                            {`${item.city} - ${item.state}`}
                          </div>
                        ))
                      : null}
                  </div>
                </ScrollArea>
              </div>

              {getInputProps().value && onClear && (
                <TextField.Slot>
                  <CloseButton type="reset" size="xs" onClick={onClear} />
                </TextField.Slot>
              )}
              {isLoading && (
                <TextField.Slot>
                  <Spinner />
                </TextField.Slot>
              )}
            </TextField.Root>
          </React.Fragment>
        );
      }}
    </Downshift>
  );
}

export default AutoComplete;
