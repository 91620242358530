import { tv } from 'tailwind-variants';

export default tv({
  base: `
    z-50

    gap-y-5

    pb-5
    pt-6
  `,
});
