import type { ReactNode, FormEvent } from 'react';

import { Dialog as DialogBase, Button, Stack, Spacer } from '@backyard-ui/core';
import { LocationOutline } from '@backyard-ui/icons';

import LocationForm from './LocationForm';
import * as localStorageUtil from '../../utils/local-storage';

import type { TopBarProps } from './TopBar';
import { useLocationStore } from './TopBar.store';
import * as tracker from './TopBar.tracker';
import {
  ALREADY_VISITED_OTHER_REGION_STORAGE_KEY,
  OTHER_REGION,
} from '../../utils/constants';

export interface DialogProps extends TopBarProps {
  children?: ReactNode;
}

const hasGeoLocation =
  typeof navigator !== 'undefined' && Boolean(navigator?.geolocation);

function Dialog(props: DialogProps) {
  const { handleLocationRecontext, locationRepository } = props;

  const { ui, data } = useLocationStore();

  const getCoordinates = () => {
    ui.toggleGeoLocation();

    return navigator.geolocation.getCurrentPosition(
      async ({ coords }: GeolocationPosition) => {
        const { latitude, longitude } = coords;

        await handleLocationRecontext({
          contextBy: 'coordinates',
          latitude: latitude.toString(),
          longitude: longitude.toString(),
        });

        tracker.location({ type: 'coordinates' });

        ui.toggleGeoLocation();
        ui.toggleModal();
      },
      null,
      {
        enableHighAccuracy: true,
        timeout: 60000,
        maximumAge: 0,
      },
    );
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!data.location?.region) {
      return;
    }

    await handleLocationRecontext({
      contextBy: 'zip_code',
      zipCodeRangeId: data.location.id,
      zipCode: data.zipCode,
    });

    const { zipCode } = data;

    const type = zipCode ? 'zipCode' : 'city';
    const regionName = `${data.location.city} - ${data.location.state}`;

    tracker.location({ type, regionName, zipCode });

    ui.toggleModal();

    if (data.location.region === OTHER_REGION) {
      localStorageUtil.setValue(ALREADY_VISITED_OTHER_REGION_STORAGE_KEY, true);
    }
  };

  return (
    <DialogBase.Root
      isCentered
      isOpen={ui.isModalOpen}
      onOpenChange={ui.toggleModal}
    >
      <DialogBase.Content data-testid="location-modal">
        <DialogBase.Title>Onde você está?</DialogBase.Title>
        <DialogBase.Description>
          Dessa forma você terá acesso aos produtos e ofertas da sua região.
        </DialogBase.Description>
        <Spacer axis="vertical" size={3} />

        <Stack direction="column" spacing={6}>
          {hasGeoLocation && (
            <Button
              variant="ghost"
              iconLeft={<LocationOutline />}
              onClick={getCoordinates}
              isFullWidth
              isLoading={ui.isLoadingGeoLocation}
            >
              Utilizar localização automática
            </Button>
          )}

          <LocationForm
            onSubmit={handleSubmit}
            locationRepository={locationRepository}
          />
        </Stack>
        <DialogBase.CloseButton />
      </DialogBase.Content>
    </DialogBase.Root>
  );
}

export default Dialog;
