import { tv } from 'tailwind-variants';

export default tv({
  base: `
    -mx-2
    my-4

    w-[calc(100%+theme('width.4'))]
   `,
  variants: {
    theme: {
      default: 'border-gray-200',
      'black-weekend': 'border-neutral-800',
    },
  },
});
